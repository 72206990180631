<template>
    <custom-vue-table :showSearchBox="false" class="mt-5" title="Attachments" :fields="fields" :url="listAttachment"
                      :per-page="10"
                      ref="table" :extra-params="{return_id}">
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn class="btn-basic-b" size="xs" icon="fa fa-eye" @click="viewFile(rowData)"></btn>
            </div>
        </template>

    </custom-vue-table>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name  : 'Attachments',
    props : {
        return_id : { type : String }
    },
    data () {
        return {
            loading        : false,
            listAttachment : urls.financeAdmin.purchaseReturn.listAttachment,
            fields         : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'file_name',
                    sortField : 'file_name',
                    title     : 'File'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewFile (item) {
            window.open(item.file);
        }
    }
};
</script>
