import { render, staticRenderFns } from "./DetailsPage.vue?vue&type=template&id=49a39af0&scoped=true&"
import script from "./DetailsPage.vue?vue&type=script&lang=js&"
export * from "./DetailsPage.vue?vue&type=script&lang=js&"
import style0 from "./DetailsPage.vue?vue&type=style&index=0&id=49a39af0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a39af0",
  null
  
)

export default component.exports