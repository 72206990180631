<template>
    <div class="mt-5">
        <div class="card bs-4 p-0 pb-3">
            <div class="fl-te-c bb-1 p-3">
                <h5>Payment Details</h5>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Amount</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.amount }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div v-if="data.payment_date" class="col-2 p-1 b-1 text-muted round-1">{{ data.payment_date }}</div>
                <div v-else class="col-2 p-1 b-1 text-muted round-1">Nil</div>
                <div class="col-1"/>

                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment_status}}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Method</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment_mode }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Reference</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.reference }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Remarks</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.remarks }}</div>
                <div class="col-1"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'PaymentDetailsView',
    props : { payment : { type : Object } },
    data () {
        return {
            data : ''
        };
    },
    mounted () {
        this.data = this.payment;
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
